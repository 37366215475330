import React, { useContext, useState } from "react";
import { PartidasContratoContext } from "../context/PartidasContratoContext";
import { EstimacionesContext } from "../context/EstimacionesContext";
import ConceptoDetail from "../components/conceptos/ConceptoDetail";
import { ConceptosContext } from "../context/ConceptosContext";
import { PreciarioContext } from "../context/PreciarioContext";
import { ModalContext } from "../context/ModalContext";
import useProyecto from "./useProyecto";
import { CapacitorContext } from "../context/CapacitorContext";
import { ContratosContext } from "../context/ContratosContext";
import { useParams } from "react-router-dom";
import BitacoraConcepto from "../components/conceptos/BitacoraConceptos";
import { EstimacionVersionesContext } from "../context/EstimacionVersionesContext";
import ConceptoPrecioAutorizadoForm from "../components/conceptos/ConceptoPrecioAutorizadoForm";
import { formatMonto } from "../utils";
import ConceptoEstimacionDetail from "../components/conceptos/ConceptoEstimacionDetail";

const usePreciario = () => {
  const [query, setQuery] = useState("");
  const [showConceptos, setShowConceptos] = useState([]);

  const params = useParams();

  const { concepto, conceptos, conceptosExtra, setConcepto } =
    useContext(ConceptosContext);
  const { estimacionversion } = useContext(EstimacionVersionesContext);
  const { estimacion, acumulados } = useContext(EstimacionesContext);
  const { view, setView, editMode } = useContext(PreciarioContext);
  const { clearModal, modalComponent } = useContext(ModalContext);
  const { partidas } = useContext(PartidasContratoContext);
  const { contrato } = useContext(ContratosContext);
  const { platform } = useContext(CapacitorContext);
  const { permiso } = useProyecto();

  const idContrato = contrato?.idContrato;
  const idEstimacion = estimacion?.idEstimacion;
  const idEstimacionVersion = params.idEstimacionVersion ? params.idEstimacionVersion : estimacionversion?.idEstimacionVersion;

  function handleComments(concepto){
    modalComponent(
      "Comentarios",
      <BitacoraConcepto
        idFolder={null}
        idConcepto={concepto.idConcepto}
        idEstimacionVersion={idEstimacionVersion}
      />
    );
  };

  function handleEditPrecio (concepto) {
    modalComponent(
      "Autorizar Precio",
      <ConceptoPrecioAutorizadoForm
        handleCancel={clearModal}
        concepto={concepto}
      />
    );
  };

  function showConceptoEstimacionDetail(concepto) {
    setConcepto(concepto);
    if (platform !== "web") {
      modalComponent(`Concepto ${concepto.clave}`, <ConceptoEstimacionDetail  />);
    }
  }


  function showConceptoDetail(concepto) {
    setConcepto(concepto);
    if (platform !== "web") {
      modalComponent(`Concepto ${concepto.clave}`, <ConceptoDetail />);
    }
  }

  function toggleConceptosPartida(idPartidaContrato) {
    const current = [...showConceptos];
    const index = showConceptos.indexOf(idPartidaContrato);
    if (index === -1) current.push(idPartidaContrato);
    else current.splice(index, 1);
    setShowConceptos(current);
  }

  let partidasRender = Array.isArray(partidas) ? [...partidas] : [];
  let conceptosRender = Array.isArray(conceptos) ? [...conceptos] : [];

  if (editMode === "partidas") {
    partidasRender = partidas.filter(
      (partida) => String(partida.idPartidaContrato)[0] !== "d"
    );
  }

  if (editMode === "conceptos-extra") {
    conceptosRender = conceptosExtra.filter(
      (concepto) => String(concepto.idConceptoExtra)[0] !== "d"
    );
  }

  if (editMode === "conceptos-estimacion") {
    if (["finanzas", "obras"].includes(permiso)) {
      let conceptosEstimados = conceptos.filter(
        (concepto) => concepto.estimado
      );
      let partidasConceptosEstimados = conceptosEstimados.map(
        (concepto) => concepto.idPartidaContrato
      );
      partidasRender = partidasRender.filter((partida) =>
        partidasConceptosEstimados.includes(partida.idPartidaContrato)
      );
    }
  }

  if (estimacion !== null) {
    partidasRender = partidasRender.filter((partida) => {
      return (
        conceptos.find(
          (concepto) => concepto.idPartidaContrato === partida.idPartidaContrato
        ) !== undefined
      );
    });
  }

  function getConceptosPartida(idPartidaContrato) {
    return conceptosRender.filter(
      (concepto) => concepto.idPartidaContrato === idPartidaContrato
    );
  }
  

  function renderPrecioControl (concepto) {
    if (
      concepto.extra &&
      permiso === "finanzas" &&
      ["conciliada-con-obras", "conciliada-con-obras-sin-contratista"].includes(
        estimacionversion?.estado
      )
    ) {
      return (
        <div className="row mx-0">
          <div className="col-12 col-md-9 px-0">
            ${formatMonto(concepto.precio_control)}
          </div>
          <div className="col-12 col-md-3 px-0">
            <button
              onClick={() => handleEditPrecio(concepto)}
              className="btn btn-sm btn-outline-primary"
            >
              <i className="fa fa-edit" />
            </button>
          </div>
        </div>
      );
    }
    let precio_control = concepto.precio_control;
    if (!concepto.extra) precio_control = concepto.precio;
    return <span>${formatMonto(precio_control)}</span>;
  };

  return {
    view,
    query,
    setView,
    setQuery,
    concepto,
    editMode,
    contrato,
    acumulados,
    idContrato,
    estimacion,
    setConcepto,
    idEstimacion,
    showConceptos,
    conceptosExtra,
    handleComments,
    handleEditPrecio,
    estimacionversion,
    showConceptoDetail,
    getConceptosPartida,
    idEstimacionVersion,
    renderPrecioControl,
    toggleConceptosPartida,
    partidas: partidasRender,
    conceptos: conceptosRender,
    showConceptoEstimacionDetail,
  };
};

export default usePreciario;
