import React, { useContext, useEffect } from "react";
import { CambioOrdenCambiosContext } from "../../context/CambioOrdenCambiosContext";
import CambioOrdenCambioCard from "./CambioOrdenCambioCard";
import useProyecto from "../../hooks/useProyecto";

const HistorialOrdenCambio = ({ idOrdenCambio }) => {
  const { idProyecto } = useProyecto();
  const { cambioordencambios, getCambioOrdenCambios } = useContext(
    CambioOrdenCambiosContext
  );

  useEffect(() => {
    getCambioOrdenCambios(idProyecto, idOrdenCambio);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idOrdenCambio]);

  const renderCambios = () => {
    if (Array.isArray(cambioordencambios)) {
      if (cambioordencambios.length === 0) {
        return <p>No hay cambios registrados en esta orden de cambio</p>;
      }
      return cambioordencambios.map((cambio) => (
        <CambioOrdenCambioCard
          key={cambio.idCambioOrdenCambio}
          cambioordencambio={cambio}
        />
      ));
    }
  };
  return (
    <div>
      <h1 className="h4">Historial de Cambios</h1>
      {renderCambios()}
    </div>
  );
};

export default HistorialOrdenCambio;
