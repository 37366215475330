import React, { useContext, useState } from "react";
import { ConceptosContext } from "../../context/ConceptosContext";
import useProyecto from "../../hooks/useProyecto";

const ConceptoPrecioAutorizadoForm = ({ concepto, handleCancel, handleCallback }) => {
  const [comentario, setComentario] = useState("");
  const [precio, setPrecio] = useState(concepto?.precio_control || 0);

  const { updatePrecioAutorizadoConcepto } = useContext(ConceptosContext);
  const { idProyecto } = useProyecto();

  const handleSubmit = (e) => {
    e.preventDefault();
    updatePrecioAutorizadoConcepto(idProyecto, {
      idConcepto: concepto.idConcepto,
      precio_control: precio,
      comentario,
    }, handleCallback);
  };

  return (
    <form onSubmit={handleSubmit}>
      <h5>{concepto.clave}</h5>
      <p>{concepto.nombre}</p>
      <div className="row">
        <div className="col-12 col-md-6">
          <label>Precio Solicitado</label>
          <input
            type="number"
            disabled
            className="form-control mb-3"
            value={concepto?.precio_unitario}
          />
        </div>
        <div className="col-12 col-md-6">
          <label>Precio Autorizado</label>
          <input
            step=".01"
            type="number"
            value={precio}
            className="form-control mb-3"
            onChange={(e) => setPrecio(e.target.value)}
          />
        </div>
      </div>
      <label>Comentario (Opcional)</label>
      <textarea
        rows="3"
        value={comentario}
        className="form-control mb-3"
        onChange={(e) => setComentario(e.target.value)}
      />
      <div className="row">
        <div className="col-6">
          <button
            type="button"
            onClick={handleCancel}
            className="btn w-100 text-muted"
          >
            Cancelar
          </button>
        </div>
        <div className="col-6">
          <button type="submit" className="btn w-100 btn-primary">
            Guardar
          </button>
        </div>
      </div>
    </form>
  );
};

export default ConceptoPrecioAutorizadoForm;
