import React, { useContext } from "react";
import { Link } from "react-router-dom";
import UserItem from "../users/UserItem";
import StatusBadge from "../common/StatusBadge";
import { ModalContext } from "../../context/ModalContext";
import BitacoraConcepto from "../conceptos/BitacoraConceptos";

const OrdenCambioCard = ({ ordencambio }) => {
  const { modalComponent } = useContext(ModalContext);

  const handleComments = (ordencambio) => {
    modalComponent(
      "Comentarios",
      <BitacoraConcepto idOrdenCambio={ordencambio.idOrdenCambio} />
    );
  };

  return (
    <tr key={ordencambio.idOrdenCambio}>
      <td>{ordencambio.folio}</td>
      <td>
        <StatusBadge text={ordencambio.estado} />
      </td>
      <td>
        <Link
          to={`/obra/${ordencambio.idProyecto}/finanzas/${ordencambio.idContrato}/concepto/${ordencambio.anterior?.idConcepto}`}
          className="text-underline"
        >
          <i className="fa fa-eye me-1" /> {ordencambio.anterior?.clave}
        </Link>
      </td>
      <td>
        <Link
          to={`/obra/${ordencambio.idProyecto}/finanzas/${ordencambio.idContrato}/concepto/${ordencambio.nuevo?.idConcepto}`}
          className="text-underline"
        >
          <i className="fa fa-eye me-1" /> {ordencambio.nuevo?.clave}
        </Link>
      </td>
      <td>
        <Link
          to={`/obra/${ordencambio.idProyecto}/supervision/${ordencambio.idFolder}`}
          className="btn btn-light border"
        >
          <i className="fa fa-folder me-1" /> {ordencambio.Folder?.nombre}
        </Link>
      </td>
      <td>
        <UserItem user={ordencambio.Usuario} size="xs" />
      </td>
      <td>
        <Link
          className="btn btn-outline-primary me-2"
          to={`./orden/${ordencambio.idOrdenCambio}`}
        >
          <i className="fa fa-eye" />
        </Link>
        <button
          className="btn btn-outline-primary me-2"
          onClick={() => handleComments(ordencambio)}
        >
          <i className="fa fa-comments" />
        </button>
      </td>
    </tr>
  );
};

export default OrdenCambioCard;
