import { HIDE_SPINNER, SHOW_SPINNER } from "../types";
import {
  SET_CAMBIOORDENCAMBIO,
  CREATE_CAMBIOORDENCAMBIO,
  CAMBIOORDENCAMBIOS_RECEIVED,
  SET_PROPERTY_CAMBIOORDENCAMBIO,
} from "../types/cambioordencambios";

const schema = {};

const CambioOrdenCambiosReducer = (state, { type, payload }) => {
  switch (type) {
    case SHOW_SPINNER:
      return { ...state, spinner: true };
    case HIDE_SPINNER:
      return { ...state, spinner: false };
    case CAMBIOORDENCAMBIOS_RECEIVED:
      return { ...state, cambioordencambios: payload };
    case SET_CAMBIOORDENCAMBIO:
      return { ...state, cambioordencambio: payload };
    case CREATE_CAMBIOORDENCAMBIO:
      return { ...state, cambioordencambio: schema };
    case SET_PROPERTY_CAMBIOORDENCAMBIO: {
      const { key, value } = payload;
      const cambioordencambio = { ...state.cambioordencambio };
      cambioordencambio[key] = value;
      return { ...state, cambioordencambio };
    }
    default:
      return { ...state };
  }
};

export default CambioOrdenCambiosReducer;
