
import api from './api';

const route = '/cambioOrdenCambio';

const CambioOrdenCambiosService = {
  getCambioOrdenCambios: (idProyecto, idOrdenCambio) => api.get(`${route}/${idProyecto}/${idOrdenCambio}`),
  getSingleCambioordencambio: (idOrdenCambio) => api.get(`${route}/${idOrdenCambio}`),
  deleteCambioordencambio: (Cambioordencambio) => api.delete(`${route}/${Cambioordencambio}`),
  postCambioordencambio: (cambioordencambio) => api.post(route, { ...cambioordencambio}),
  putCambioordencambio: (cambioordencambio) => api.put(route, { ...cambioordencambio}),
  verifyCambioOrdenCambio: (
    idProyecto,
    idOrdenCambio,
    code
  ) =>
    api.post(`${route}/${idProyecto}/${idOrdenCambio}/verify`, {
      idOrdenCambio,
      code,
    }),
  postNotificacionesCambioOrdenCambio: (
    idProyecto,
    idOrdenCambio,
  ) =>
    api.post(
      `${route}/${idProyecto}/${idOrdenCambio}/notifications`
    ),
};

export default CambioOrdenCambiosService;