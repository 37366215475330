import React, { useContext, useEffect, useState } from "react";
import Folder from "./Folder";
import { formatMonto } from "../utils";
import { useParams } from "react-router-dom";
import useProyecto from "../hooks/useProyecto";
import { ModalContext } from "../context/ModalContext";
import OffCanvas from "../components/global/OffCanvas";
import StatusBadge from "../components/common/StatusBadge";
import ConfirmDelete from "../components/global/ConfirmDelete";
import { OrdenesCambioContext } from "../context/OrdenesCambioContext";
import BitacoraConcepto from "../components/conceptos/BitacoraConceptos";
import HistorialOrdenCambio from "../components/ordenes/HistorialOrdenCambio";
import OrdenCambioCierreForm from "../components/ordenes/OrdenCambioCierreForm";
import ConceptoPrecioAutorizadoForm from "../components/conceptos/ConceptoPrecioAutorizadoForm";

const SingleOrdenCambio = () => {
  const [showHistory, setShowHistory] = useState(false);
  const { clearModal, modalComponent } = useContext(ModalContext);
  const { ordencambio, getSingleOrdenCambio, deleteOrdenCambio } =
    useContext(OrdenesCambioContext);

  const { idOrdenCambio } = useParams();
  const { idProyecto } = useParams();
  const { permiso } = useProyecto();

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idOrdenCambio]);

  const fetchData = () => getSingleOrdenCambio(idProyecto, idOrdenCambio);

  const handleCallback = () => {
    clearModal();
    fetchData();
  }

  const canClose = () => {
    if (!ordencambio || ordencambio === null) return false;
    if (permiso === "admin") return true;
    const { estado } = ordencambio;
    return (
      (permiso === "supervisor" && estado === "enviada") ||
      (permiso === "contratista" &&
        ["creada", "autorizada-control"].includes(estado)) ||
      (permiso === "obras" && estado === "revisada") ||
      (permiso === "gerencia" && estado === "conciliada") ||
      (permiso === "finanzas" && estado === "autorizada")
    );
  };

  const handleEditPrecio = (concepto) => {
    modalComponent(
      "Autorizar Precio",
      <ConceptoPrecioAutorizadoForm
        handleCallback={handleCallback}
        handleCancel={clearModal}
        concepto={concepto}
      />
    );
  };

  const renderPrecioControl = (concepto) => {
    if (permiso === "finanzas" && ordencambio.estado === "autorizada") {
      return (
        <div className="row align-items-center mx-0">
          <div className="col-12 col-md-9 px-0">
            ${formatMonto(concepto.precio_control)}
          </div>
          <div className="col-12 col-md-3 px-0 text-end">
            <button
              onClick={() => handleEditPrecio(concepto)}
              className="btn btn-sm btn-outline-primary ms-auto me-0"
            >
              <i className="fa fa-edit" />
            </button>
          </div>
        </div>
      );
    }
    const { precio_control, precio_unitario } = concepto;
    return <span>${formatMonto(precio_control || precio_unitario)}</span>;
  };

  const handleComments = () => {
    modalComponent(
      "Comentarios",
      <BitacoraConcepto
        idOrdenCambio={ordencambio.idOrdenCambio}
        idConcepto={ordencambio.nuevo.idConcepto}
      />
    );
  };

  const handleCierre = () => {
    modalComponent(
      "Cerrar Orden de Cambio",
      <OrdenCambioCierreForm
        handleCancel={clearModal}
        handleCallback={fetchData}
      />
    );
  };

  const handleDelete = (ordencambio) => {
    modalComponent(
      "Editar Orden de Cambio",
      <ConfirmDelete
        message={`¿Estás seguro que deseas eliminar la Orden de Cambio ${ordencambio.folio}?`}
        handleDelete={() => deleteOrdenCambio(ordencambio.idOrdenCambio)}
        handleCancel={clearModal}
      />
    );
  };

  const renderOrdenCambio = () => {
    if (ordencambio && ordencambio !== null) {
      const { nuevo, anterior } = ordencambio;
      if (nuevo && nuevo !== null && anterior && anterior !== null)
        return (
          <div>
            <div className="card p-3 shadow mb-4">
              <h3 className="h5 bold border-bottom pb-2 mb-2">
                Concepto Anterior
              </h3>
              <p>
                {"["}
                {anterior.clave}
                {"]"}: {anterior.concepto}
              </p>
              <div className="table-responsive">
                <table className="table border">
                  <thead>
                    <tr className="bg-light bold border">
                      <th>Unidad</th>
                      <th>Precio</th>
                      <th>Cantidad</th>
                      <th>Importe</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{anterior.unidad}</td>
                      <td>${formatMonto(anterior.precio_unitario)}</td>
                      <td>{anterior.cantidad}</td>
                      <td>${formatMonto(anterior.importe)}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="card p-3 shadow mb-4">
              <h3 className="h5 bold border-bottom pb-2 mb-2">
                Concepto Nuevo
              </h3>
              <p>
                {"["}
                {nuevo.clave}
                {"]"}: {nuevo.concepto}
              </p>
              <div className="table-responsive">
                <table className="table border">
                  <thead>
                    <tr className="bg-light bold border">
                      <th>Unidad</th>
                      <th>Precio Solicitado</th>
                      <th>Precio Autorizado</th>
                      <th>Cantidad</th>
                      <th>Importe</th>
                      <th>Acciones</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{nuevo.unidad}</td>
                      <td>${formatMonto(nuevo.precio_unitario)}</td>
                      <td>{renderPrecioControl(nuevo)}</td>
                      <td>{nuevo.cantidad}</td>
                      <td>${formatMonto(nuevo.importe)}</td>
                      <td>
                        <button
                          className="btn btn-outline-primary me-2"
                          onClick={() => handleComments(ordencambio)}
                        >
                          <i className="fa fa-comments" />
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="card p-3 shadow mb-4">
              <Folder idFolder={ordencambio.idFolder} hideFolderButton />
            </div>
          </div>
        );
    }
  };

  return (
    <div className="container-fluid">
      <div className="row align-items-center">
        <div className="hide-mobile col-12 col-md-6 col-xl-9 mb-3">
          <h1 className="h4 mb-0">Orden de Cambio #{ordencambio?.folio}</h1>
          <div className="d-inline-block">
            <StatusBadge fluid text={ordencambio?.estado} />
          </div>
        </div>
        <div className="col-12 col-md-6 col-xl-3 mb-4 text-md-end">
          <div className="hide-mobile">
            {canClose() && (
              <button
                onClick={handleCierre}
                className="btn btn-outline-primary me-2"
              >
                <i className="fas fa-envelope-open-text me-1"></i> Cerrar
              </button>
            )}
            <button
              className="btn btn-outline-dark"
              onClick={() => setShowHistory(!showHistory)}
            >
              <i className="fas fa-history" />
            </button>
          </div>
        </div>
      </div>
      {renderOrdenCambio()}
      <OffCanvas showOffCanvas={showHistory} setShowOffCanvas={setShowHistory}>
        <HistorialOrdenCambio idOrdenCambio={idOrdenCambio} />
      </OffCanvas>
    </div>
  );
};

export default SingleOrdenCambio;
