import React from "react";
import OrdenCambioCard from "./OrdenCambioCard";

const OrdenesCambioTable = ({ ordenescambio = [] }) => (
  <div className="table-responsive">
    <table className="table">
      <thead>
        <tr className="bg-light border">
          <th>Folio</th>
          <th>Estado</th>
          <th>Anterior</th>
          <th>Nuevo</th>
          <th>Folder</th>
          <th>Usuario</th>
          <th>Acciones</th>
        </tr>
      </thead>
      <tbody>
        {ordenescambio.map((orden) => (
          <OrdenCambioCard ordencambio={orden} key={orden.idOrdenCambio} />
        ))}
      </tbody>
    </table>
  </div>
);

export default OrdenesCambioTable;
