import React, { useContext, useEffect } from "react";
import useProyecto from "../hooks/useProyecto";
import usePreciario from "../hooks/usePreciario";
import { ModalContext } from "../context/ModalContext";
import OrdenCambioForm from "../components/ordenes/OrdenCambioForm";
import { OrdenesCambioContext } from "../context/OrdenesCambioContext";
import OrdenesCambioTable from "../components/ordenes/OrdenesCambioTable";

const OrdenesCambio = () => {
  const { ordenescambio, getOrdenesCambio } = useContext(OrdenesCambioContext);
  const { clearModal, modalComponent } = useContext(ModalContext);
  const { idContrato } = usePreciario();
  const { idProyecto } = useProyecto();

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idProyecto, idContrato]);

  const fetchData = () => getOrdenesCambio(idProyecto, idContrato);

  const handleCreate = () => {
    modalComponent(
      "Crear Orden de Cambio",
      <OrdenCambioForm handleCancel={clearModal} handleCallback={fetchData} />
    );
  };

  const renderOrdenes = () => {
    if (Array.isArray(ordenescambio)) {
      if (ordenescambio.length === 0) {
        return <p>No hay ordenes de cambio para este contrato</p>;
      }
      return <OrdenesCambioTable ordenescambio={ordenescambio} />;
    }
  };

  return (
    <div className="card shadow p-3">
      <div className="row align-items-center mb-2">
        <div className="col">
          <h3 className="h5 mb-0">Ordenes de Cambio</h3>
        </div>
        <div className="col text-end">
          <button onClick={handleCreate} className="btn btn-primary">
            <i className="fa fa-plus" />
          </button>
        </div>
      </div>
      {renderOrdenes()}
    </div>
  );
};

export default OrdenesCambio;
