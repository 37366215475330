
import React, { createContext, useReducer, useContext } from 'react';
import OrdenesCambioService from '../services/OrdenesCambioService';
import OrdenesCambioReducer from '../reducers/OrdenesCambioReducer';
import {
  ORDENCAMBIOS_RECEIVED,
  SET_ORDENCAMBIO,
  CREATE_ORDENCAMBIO,
  SET_PROPERTY_ORDENCAMBIO,
} from "../types/ordenescambio";
import { ModalContext } from './ModalContext';
import { HIDE_SPINNER, SHOW_SPINNER } from "../types";
import { AdjuntosContext } from './AdjuntosContext';

const initialState = {
  ordenescambio: null,
  ordencambio: null,
};

export const OrdenesCambioContext = createContext(initialState);

export const OrdenesCambioProvider = ({ children }) => {
  const [state, dispatch] = useReducer(OrdenesCambioReducer, initialState);

  const { alert, success, clearModal } = useContext(ModalContext);
  const { uploadAdjuntos } = useContext(AdjuntosContext);

  const getOrdenesCambio = (idProyecto, idContrato) => {
    OrdenesCambioService.getOrdenesCambio(idProyecto, idContrato)
      .then((response) => {
        const { ordenescambio } = response.data;
        dispatch({ type: ORDENCAMBIOS_RECEIVED, payload: ordenescambio });
      })
      .catch((error) => {
        alert(error);
      });
  };

  const getSingleOrdenCambio = (idProyecto, idOrdenCambio) => {
    OrdenesCambioService.getSingleOrdenCambio(idProyecto, idOrdenCambio)
      .then((response) => {
        const { ordencambio } = response.data;
        dispatch({ type: SET_ORDENCAMBIO, payload: ordencambio });
      })
      .catch((error) => {
        alert(error);
      });
  };

  const setOrdenCambio = (ordencambio) => {
    dispatch({ type: SET_ORDENCAMBIO, payload: ordencambio });
  };

  const createOrdenCambio = () => {
    dispatch({ type: CREATE_ORDENCAMBIO });
  };

  const setPropertyOrdenCambio = (key, value) => {
    dispatch({ type: SET_PROPERTY_ORDENCAMBIO, payload: { key, value } });
  };

  const saveOrdenCambio = (idProyecto, ordencambio, callback) => {
      dispatch({ type: SHOW_SPINNER });
      let service = OrdenesCambioService.putOrdenCambio;
      if(isNaN(parseInt(ordencambio.idOrdenCambio))) {
        service = OrdenesCambioService.postOrdenCambio;
      }
      const { adjuntos } = ordencambio;
      delete ordencambio.adjuntos;
      service(idProyecto, ordencambio).then((res) => {
        ordencambio = res.data.ordencambio;
        const cambiordencambio = res.data.cambioordencambio;
        const { idFolder } = ordencambio;
        if (Array.isArray(adjuntos) && adjuntos.length > 0) {
          uploadAdjuntos(idProyecto, idFolder, adjuntos);
        }
        dispatch({ type: HIDE_SPINNER });
        if(typeof callback === "function") {
          callback(cambiordencambio);
        }
      })
      .catch((error) => {
        dispatch({ type: HIDE_SPINNER });
        alert(error);
      });
  };

  const deleteOrdenCambio = (idOrdenCambio, callback) => {
     dispatch({ type: SHOW_SPINNER });
    OrdenesCambioService.deleteOrdenCambio(idOrdenCambio).then(() => {
      success("Orden de Cambio eliminada.");
      dispatch({ type: HIDE_SPINNER });
      clearModal();
      if(typeof callback === "function") {
        callback();
      }
    }).catch(error => {
      dispatch({ type: HIDE_SPINNER });
      alert(error);
    })
  };

 

  return (
    <OrdenesCambioContext.Provider
    value={{
      ...state,
      setOrdenCambio,
      getOrdenesCambio,
      saveOrdenCambio,
      deleteOrdenCambio,
      createOrdenCambio,
      getSingleOrdenCambio,
      setPropertyOrdenCambio,

    }}
  >
    {children}
  </OrdenesCambioContext.Provider>
);
};
