
import React, { createContext, useReducer, useContext } from 'react';
import CambioOrdenCambiosService from '../services/CambioOrdencambiosService';
import CambioOrdenCambiosReducer from '../reducers/CambioOrdencambiosReducer';
import {
  CAMBIOORDENCAMBIOS_RECEIVED,
  SET_CAMBIOORDENCAMBIO,
  CREATE_CAMBIOORDENCAMBIO,
  SET_PROPERTY_CAMBIOORDENCAMBIO,
} from "../types/cambioordencambios";
import { ModalContext } from './ModalContext';
import { HIDE_SPINNER, SHOW_SPINNER } from "../types";

const initialState = {
  cambioordencambios: null,
  cambioordencambio: null,
};

export const CambioOrdenCambiosContext = createContext(initialState);

export const CambioOrdenCambiosProvider = ({ children }) => {
  const [state, dispatch] = useReducer(CambioOrdenCambiosReducer, initialState);

  const { alert, success, clearModal } = useContext(ModalContext);

  const getCambioOrdenCambios = (idProyecto, idOrdenCambio) => {
    CambioOrdenCambiosService.getCambioOrdenCambios(idProyecto, idOrdenCambio)
      .then((response) => {
        const { cambioordencambios } = response.data;
        dispatch({ type: CAMBIOORDENCAMBIOS_RECEIVED, payload: cambioordencambios });
      })
      .catch((error) => {
        alert(error);
      });
  };

  const getSingleCambioordencambio = (idOrdenCambio) => {
    CambioOrdenCambiosService.getSingleCambioordencambio(idOrdenCambio)
      .then((response) => {
        const { cambioordencambio } = response.data;
        dispatch({ type: SET_CAMBIOORDENCAMBIO, payload: cambioordencambio });
      })
      .catch((error) => {
        alert(error);
      });
  };

  const setCambioOrdenCambio = (cambioordencambio) => {
    dispatch({ type: SET_CAMBIOORDENCAMBIO, payload: cambioordencambio });
  };

  const createCambioordencambio = () => {
    dispatch({ type: CREATE_CAMBIOORDENCAMBIO });
  };

  const setPropertyCambioordencambio = (key, value) => {
    dispatch({ type: SET_PROPERTY_CAMBIOORDENCAMBIO, payload: { key, value } });
  };

  const saveCambioordencambio = (cambioordencambio, callback) => {
      dispatch({ type: SHOW_SPINNER });
      let service = CambioOrdenCambiosService.putCambioordencambio;
      if(isNaN(parseInt(cambioordencambio.idOrdenCambio))) {
        service = CambioOrdenCambiosService.postCambioordencambio;
      }
      service(cambioordencambio).then((res) => {
        dispatch({ type: HIDE_SPINNER });
        if(typeof callback === "function") {
          callback(res.data.cambioordencambio);
        }
      })
      .catch((error) => {
        dispatch({ type: HIDE_SPINNER });
        alert(error);
      });
  };

  const deleteCambioordencambio = (idOrdenCambio, callback) => {
     dispatch({ type: SHOW_SPINNER });
    CambioOrdenCambiosService.deleteCambioordencambio(idOrdenCambio).then(() => {
      success("Cambioordencambio deleted.");
      dispatch({ type: HIDE_SPINNER });
      clearModal();
      if(typeof callback === "function") {
        callback();
      }
    }).catch(error => {
      dispatch({ type: HIDE_SPINNER });
      alert(error);
    })
  };

    const verifyCambioOrdenCambio = (
      idProyecto,
      idCambioOrdenCambio,
      code,
      callback
    ) => {
      dispatch({ type: SHOW_SPINNER });
      CambioOrdenCambiosService.verifyCambioOrdenCambio(
        idProyecto,
        idCambioOrdenCambio,
        code
      )
        .then(() => {
          clearModal();
          dispatch({ type: HIDE_SPINNER });
          dispatch({ type: SET_CAMBIOORDENCAMBIO, payload: null });
          if (typeof callback === "function") {
            callback();
          }
        })
        .catch((error) => {
          dispatch({ type: HIDE_SPINNER });
          alert(error);
        });
    };

    const resendNotifications = (idProyecto, idCambioOrdenCambio, callback) => {
      CambioOrdenCambiosService.postNotificacionesCambioOrdenCambio(
        idProyecto,
        idCambioOrdenCambio
      )
        .then(() => {
          success("Notifcaciones reenviadas.");
          if(typeof callback === "function") {
            callback();
          }
        })
        .catch((error) => {
          dispatch({ type: HIDE_SPINNER });
          alert(error);
        });
    };
 

  return (
    <CambioOrdenCambiosContext.Provider
    value={{
      ...state,
      resendNotifications,
      setCambioOrdenCambio,
      getCambioOrdenCambios,
      saveCambioordencambio,
      deleteCambioordencambio,
      createCambioordencambio,
      verifyCambioOrdenCambio,
      getSingleCambioordencambio,
      setPropertyCambioordencambio,
    }}
  >
    {children}
  </CambioOrdenCambiosContext.Provider>
);
};
